<template>
  <div>
    <!-- Page Title goes here -->
    <div>
      <b-row class="admin-text" >
        <h5 class="mb-3">Manage Awards !</h5>
      </b-row>         
    </div>
  
    <!-- Main Tab section start here  -->
    <div>
      <b-tabs content-class="mt-3">
        <!-- First Tab to Display Records -->
        <b-tab title="List All Records" active>
          <b-row>
            <b-col sm="6" class="mb-3"><b-form-input v-model="SearchBy" placeholder="Search by name"></b-form-input></b-col>
            <b-col sm="6" class="mb-3"><b-form-input v-model="SearchTable" v-on:keyup="AdminSearchShows()" placeholder="Search in Table"></b-form-input></b-col>
          </b-row>
          <div >
            <b-table striped hover outlined responsive="sm"
              :items="ListAllRec"
              :fields="ListFields"
              :per-page="perPage"
              :current-page="currentPage"    
            >
            <template v-slot:cell(actions)="data">
              <b-button variant="danger" @click="DelTableRec(data.item.seq_no)">D</b-button>
              <b-button variant="success" @click="DelTableRec(data.item.seq_no)">E</b-button>
            </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>  
          </div>        
        </b-tab>

        <!-- Second Tab to Create new Records -->        
        <b-tab title="Create New Record">
          <b-row class="text-center">
            <b-col cols="8 my-4">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-AwardName" label-for="AwardName" class="col-8">
                  <b-form-input
                    id="AwardName"
                    v-model="InpForm.AwardName"
                    placeholder="Enter Award name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-AwardArea" label-for="AwardArea" class="col-8">
                  <b-form-select 
                    v-model="InpForm.AwardArea" 
                    :options="ListAwardArea">
                  </b-form-select>
                </b-form-group>

                <b-form-group class="col-8">
                  <b-form-textarea
                    id="textarea"
                    size="md"
                    v-model="InpForm.AwardDesc"
                    placeholder="Enter Award Description"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>  
                </b-form-group>

                <b-row>
                  <b-col cols="5">         
                     <b-button class="float-left ml-3" variant="success"  @click="click1">choose a photo
                    </b-button>
                     <input type="file" ref="input1"
                      style="display: none"
                      @change="previewImage" accept="image/*" >   
                  </b-col>
                    <b-col cols="6" >           
                    <b-img v-if="imageData!=null" class="float-left" rounded height="80" width="80" :src="PhotoPreview" >                 
                    </b-img>                
                  </b-col>
                </b-row>

                <div class="mt-4">
                  <b-button pill type="submit" variant="primary">Submit</b-button>
                  <b-button pill type="reset" variant="danger">Reset</b-button>
                </div>
              </b-form>        
            </b-col>
          </b-row>          
        </b-tab>

        <!-- Third Tab to see Inactive Records -->        
        <b-tab title="Publish New Records!">
          <div >
            <b-table striped hover outlined responsive="sm"
              :items="ListNewRec"
              :fields="ListFields"
              :per-page="perPage"
              :current-page="currentPage"    
            >
            <template v-slot:cell(actions)="data">
              <b-button variant="info" @click="UpdRecStatus(data.item.seq_no)">A</b-button>
              <b-button variant="danger" @click="DelTableRec(data.item.seq_no)">D</b-button>
              <b-button variant="success" @click="DelTableRec(data.item.seq_no)">E</b-button>
            </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>  
          </div>
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      // Table display columns
      ListFields: [{key: 'seq_no',sortable: true}, "Title", "Catg","Status","actions"],
      // Pagination variables
      perPage: 20,
      currentPage: 1,
      // Input form variables
      InpForm: {
        AwardName: '',
        AwardArea: 'default',
        AwardDesc: '',
      },
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/awards/',
      imageData: null,
      // Response array variables
      RespDel: [],
      RespIns: [],
      RespUpdSts: [],
      RespAllRecs: [],
      RespNewRecs: [],
      RespSearch: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],
      // Select drop down options
      ListAwardArea: [
        { value: 'default', text: 'Select Award Area' },
        { value: 'Arts', text: 'Arts' },
        { value: 'Movies', text: 'Movies'},
      ],
      // Other variables
      show: true,
      SearchBy: '',
      SearchTable: '',
      NextSeqNo: '',
      }
    },
    mounted()
    {
      this.RefreshData()
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.InsAwards()
      },

      RefreshData() {
        this.AdminSearchShows()
        this.AdminNewShows()
        // this.GetMaxID()
      },

      // GetMaxID(){
      //   const REQformData = new FormData();
      //   REQformData.append('table_name', 'awards_main');          
      //   Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetMaxID',REQformData)
      //   .then((resp)=>{
      //   this.RespGetMaxID=resp.data.GetMaxID;
      //   const FirstObject = this.RespGetMaxID[0];
      //   this.NextSeqNo = FirstObject.tbl_next_seq;
      //   console.log('MaxSeqNo',this.NextSeqNo)
      // })
      // },
        
      // AdminAllAwards(){  
      //   Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=AdminSearchShows')
      //   .then((resp)=>{
      //   this.RespAllRecs=resp.data.AdminSearchShows;
      // })
      // },

      AdminSearchShows(){
        const SearchformData = new FormData();
        SearchformData.append('SearchString', this.SearchTable);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=AdminSearchShows', SearchformData)
        .then((resp)=>{
        this.RespAllRecs=resp.data.AdminSearchShows;
       }).catch(error => console.log(error));
       },        

      AdminNewShows(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=AdminNewShows')
        .then((resp)=>{
        this.RespNewRecs=resp.data.AdminNewShows;
      })
      },    
          
      InsAwards(){  
        const REQformData = new FormData();
        REQformData.append('seq_no', this.NextSeqNo);
        REQformData.append('Title', this.InpForm.AwardName);
        REQformData.append('awards_area', this.InpForm.AwardArea);
        REQformData.append('awards_description', this.InpForm.AwardDesc);
        REQformData.append('awards_photo', this.PhotoUrl + this.NextSeqNo + '/image1.jpg');                
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsAwards',REQformData)
        .then((resp)=>{
          this.RespIns=resp.data.message;
          alert('Inserted Successfully!!')
          this.RefreshData()
          this.ClearForms()
      })
      },

      DelTableRec(awards_id){
        const InsformData = new FormData();
        InsformData.append('table_name', 'awards_main');
        InsformData.append('seq_no', awards_id);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelTableRec', InsformData)
        .then((resp)=>{
        this.RespDel=resp.data.message;
        alert('Deleted Successfully!!')
        this.RefreshData()
       }).catch(error => console.log(error));
       },

      UpdRecStatus(AwardId){
        const InsformData = new FormData();
        InsformData.append('table_name', 'awards_main');
        InsformData.append('seq_no', AwardId);
        InsformData.append('Status', 'P');
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=UpdRecStatus', InsformData)
        .then((resp)=>{
        this.RespUpdSts=resp.data.message;
        alert('Approved Successfully!!')
        this.RefreshData()
       }).catch(error => console.log(error));
       },
       
      ClearForms(){  
        this.InpForm.AwardName = '';
        this.InpForm.AwardArea = 'default';
        this.InpForm.AwardDesc = '';
        this.imageData=null;    
      },
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        this.onUpload()
      },

      onUpload(){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('awards/' + this.NextSeqNo +'/' + 'image1.jpg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                this.PhotoPreview =url;
                console.log(this.PhotoPreview)
              });
            }      
          );
       },
    },

    computed: {
      ListAllRec: function(){
        return this.RespAllRecs.filter((RespAllRecs) => {
        return RespAllRecs.Title.toLowerCase().match(this.SearchBy.toLowerCase()) && RespAllRecs.Status == 'P'
      });
      },

      ListNewRec: function(){
        return this.RespNewRecs.filter((RespNewRecs) => {
        return RespNewRecs.Status != 'P'
      });   
      },
      rows() {
        return this.ListAllRec.length;
      },
    }    
  }
</script>
